export const getCurrentPartners = `
#graphql
query CurrentPartners($siteName: String!) {
  Partners(
    filter: {
      sites: {
        Sites_id: {
          domain_name: {
            _eq: $siteName
          }
        }
      }
    }) {
    id
    partners {
      Partner_logos_id {
        name
        logo {
          id
          title
        }
      }
    }
  }
}
`;
