export const GetSiteInfoByName = `
    #graphql
    query GetSiteInfoByName($slug: String)
      {
        Sites(filter:{domain_name:{_eq: $slug}}){
            id
            site_logo{
              id
            }
            site_url
            site_name
            domain_name
            discount
            discount_value           
            site_colors
            page_title
            css_file
            primary_color
            secondary_color
            login_method
            pro_subscription
            pro_price
            google_recaptcha_site_key
            editor_icon{
              id
            }
            favicon{
              id
            }        
            algolia_categories_indexname
            algolia_products_indexname
            css_variables
            editor_watermark
            brand_watermark
            brand_watermark_text
            brand_watermark_color
            brand_watermark_logo{
              id
              width
              height
              type
            }
            editor_watermark_logo{
              id
              width
              height
            }
            replace_image_popup_video
            max_timeline_duration
        }
      }
`;

export const GetSiteLanguageByName = `
    #graphql
    query GetSiteInfoByName($slug: String)
      {
        Sites(filter:{domain_name:{_eq: $slug}}){
            id
            site_name
            default_language{
              id
              name
            }
        }
      }
`;

export const GetLoginInfoByName = `
    #graphql
    query GetLoginInfoByName($slug: String)
      {
        Sites(filter:{domain_name:{_eq: $slug}}){
            id            
            site_url
            site_name
            domain_name
            google_client_id
            google_secret
            shluchim_client_id
            shluchim_client_secret      
          
            
            
        }
      }
`;

export const GetSiteInfoById = `
    #graphql
    query GetSiteInfoById($id: ID!)
      {
        Sites_by_id(id: $id){
            id
            site_logo
            {
              id
            }
            site_url
            site_colors
            page_title
            css_file
            login_method
            google_client_id
            google_secret           
            pro_subscription
            css_variables
            favicon
            {
              id
            }
        }
      }
`;

export const GetProStatusById = `
    #graphql
    query GetProStatusById($slug: String)
      {
        Sites(filter:{domain_name:{_eq: $slug}}){
            id           
            pro_subscription                      
        }
      }
`;

export const GetSiteStripeDetailsById = `
    #graphql
    query GetSiteStripeDetailsById($id: ID!)
      {
        Sites_by_id(id: $id) {
          id
          site_name
          stripe_public_key
          stripe_secret_key
          stripe_monthly_price_id
          stripe_yearly_price_id
        }
      }
`;

export const GetSiteStripeDetailsBySiteName = `
    #graphql
    query GetSiteStripeDetailsBySiteName($siteName: String)
      {
        Sites(filter:{ domain_name: { _eq: $siteName } }) {
          id
          discount
          discount_value
          site_name
          site_url
          stripe_public_key
          stripe_secret_key
          stripe_monthly_price_id
          stripe_yearly_price_id
        }
      }
`;

export const GetSiteFooterInfoByName = `
    #graphql
    query GetSiteFooterInfoByName($slug: String)
      {
        Sites(filter:{domain_name:{_eq: $slug}}) {
          footer {
            logo {
              id
            }
          }
          footer_links {
            label
            href
          }
          social_media_links {
            platform
            url
          }
        }
      }
`;

export const GetSiteAlgoliaDetailsByName = `
  #graphql
  query GetSiteAlgoliaDetailsByName($domain: String){
    Sites(filter:{domain_name:{_eq: $domain}}){
      id
      algolia_app_id
      algolia_search_api_key            
      algolia_categories_indexname
      algolia_products_indexname
    }
  }
`;
