export const MyProjects = `
    #graphql
    query MyProjects ($userid: String!){
      saveddesigns(filter: {users: {id: {_eq: $userid} }},limit: 100,sort:"-date_created")
      {
        id
        design_name
        thumbnail
        date_updated
        product{
          product_type{
            width
            height
            name
          }
        }
      }
    }
`;

export const MyCreatedProducts = `
    #graphql
    query MyCreatedProducts ($userid: String!){
      products(filter: {users: {id: {_eq: $userid} }},limit: 1000,sort:"-date_created")
      {
        id
        title
        slug
        status
        thumbnail {
          id
        }
      }
    }
`;

export const MyHomeProjects = `
    #graphql
    query MyHomeProjects ($userid: String!){
      saveddesigns(filter: {users: {id: {_eq: $userid} }},limit: 6,sort:"-date_created"){
        id
        design_name
        date_updated
        thumbnail
        product{
          product_type{
            width
            height
            name
          }
        }
      }
    }
`;

export const MyProject = `
    #graphql
    query MyProject ($designid: String!){
      saveddesigns(filter: {id: {_eq: $designid} })
      {
        id
        design_name
        design_json
        thumbnail
        date_updated
        product
        {
          id
          title
          description
          slug
          price
          no_brand_watermark
          show_replace_image_popup
          large_background
          {
            id
          }
          product_type
          {
            width
            height
            name
          }
          site_id {
            Sites_id {
              id
            }
          }
        }
        users
        {
          id
        }
      }
    }
`;

export const deleteProject = `
    mutation deleteProject($id: ID!) {
      delete_saveddesigns_item(id: $id)
      {
        id
      }
    }
`;
