export const GetTypeSensePhotos = `
query GetTypeSensePhotos($limit: Int, $page: Int) {
  photos(
    limit: $limit
    page: $page
    filter: {
      _and: [
        { typesense: { _null: true } }
        { tagged: { _eq: "yes" } }
        { photo_uploaded: { _eq: true } }
      ]
    }
  ) {
    id
    name
    tags
    status
    preview_photo {
      id
      type
    }
    thumbnail {
      id
      type
    }
    photo_url
    source{
      id
      url
      name
    }
    
  }
}
`;

export const GetPhotoByID = `

query GetSinglePhoto($id: ID!) {
  photos_by_id(id: $id) {
    id
    name
    tags
    status
    preview_photo {
      id
      type
    }
    thumbnail {
      id
      type
    }
    photo_url
    source{
      id
      url
      name
    }
  }
}
`;
