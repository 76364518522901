import fetchData from '../../fetchData';
import { getCurrentPartners } from './queries';

export const getPartners = async (siteName) => {
  const data = await fetchData(getCurrentPartners, {
    variables: {
      siteName,
    },
  });

  return data.data;
};
