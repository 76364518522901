export const GetLines = `
  #graphql
  query GetLines{
    lines(filter: {status: {_eq: "published"}}){
      id
      line_json
      line_title
      line_image{
        id
      }
    }
  }
`;

export const GetShapes = `
  #graphql
  query GetShapes($limit: Int, $page: Int){
    shapes(limit: $limit, page: $page){
      id
      shape_icon{
        id
        type
      }
      shape_title
      tags
      status
      thumbnail{
        id 
      }
    }
  }
`;

export const GetPaginatedShapes = `
  #graphql
  query GetShapes($limit: Int, $page: Int){
    shapes(limit: $limit, page: $page, filter:{typesense : {_null: true}}){
      id
      shape_icon{
        id
        type
      }
      shape_title
      tags
      status
      thumbnail{
        id 
      }
    }
  }
`;

export const GetCategoryCount = `
  #graphql
  query GetCategoryCount($slug: String!){
    products(filter: {slug: {_eq: $slug}}){
      category{
        Product_categories_cat_id{
          category_name
          cat_id
          clipart_func{
            count
          }
        }
      }
    }
  }
`;

export const GetSources = `
  #graphql
  query GetSources{
    sources{
      id
      name
      url
    }
  }
`;

export const GetPaginatedIcons = `
  query GetPaginatedIcons($limit: Int, $page: Int){
    icons(limit: $limit, page: $page, filter:{typesense : {_null: true}}){
      id
			icon_id
      icon_name
      status
      icon_file{
        id
        type
      }
      thumbnail{
        id
      }
      tags
      attribution_name
      attribution_link
      typesense
    }
  }
`;

export const GetSingleIcon = `
  #graphql
  query GetSingleIcon($id: ID!){
		icons_by_id(id: $id){
      id
			icon_id
      icon_name
      status
      icon_file{
        id
        type
      }
      tags
      attribution_name
      attribution_link
    }
  }
`;

export const GetPaginatedCliparts = `
#graphql
query GetPaginatedCliparts($limit: Int, $page: Int) {
  clipart(limit: $limit, page: $page, filter:{typesense : {_null: true}}) {
    id
    status
    clipart_title
    clipart_image {
      id
      type
    }
    thumbnail{
      id
    }
    tags
    product_categories {
      Product_categories_cat_id {
        category_name
      }
    }
    source{
      url
      name
    }
  }
}
`;

export const GetSingleClipart = `
#graphql
query GetSingleClipart($id: ID!) {
  clipart_by_id(id: $id) {
    id
    clipart_title
    status
    clipart_image {
      id
      type
    }
    tags
    product_categories {
      Product_categories_cat_id {
        category_name
      }
    }
    source{
      url
      name
    }
  }
}
`;

export const GetSingleShape = `
#graphql
query GetSingleShape($id: ID!) {
  shapes_by_id(id: $id) {
    id
    shape_title
    shape_icon {
      id
      type
    }
    status
    tags
  }
}
`;
