import Image from 'next/image';

import { useSiteInfo } from '@/context/SiteInfoContext';

const LoginChabad = ({ submitLoginWithGoogle }) => {
  const { siteInfo } = useSiteInfo();
  const CHABAD_PUBLIC_KEY = process.env.NEXT_PUBLIC_CHABAD_PUBLIC_KEY;
  return (
    <>
      <a
        href={`https://api.chabad.org/api/login/form/sso?${CHABAD_PUBLIC_KEY}`}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          padding: '8px 16px',
          width: '100%',
          backgroundColor: '#FFFFFF',
          color: '#555555',
          border: '1px solid #DDDDDD',
          borderRadius: '4px',
          fontFamily: 'Roboto, Arial, sans-serif',
          fontSize: '14px',
          fontWeight: '500',
          textDecoration: 'none',
          textAlign: 'center',
          cursor: 'pointer',
          padding: '10px 16px',
          boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        }}
      >
        Login with Chabad.org
      </a>

      {siteInfo.login_method.length > 1 && (
        <span className="divider">
          <hr />
          <label>OR</label>
        </span>
      )}
    </>
  );
};
export default LoginChabad;
