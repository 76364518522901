import fetchData from '../../fetchData';
import { GetPhotoByID, GetTypeSensePhotos } from './queries';

export const getTypeSensePhotos = async (variables = {}) => {
  const data = await fetchData(GetTypeSensePhotos, {
    variables,
  });

  return data.data;
};

export const getPhotoById = async (variables = {}) => {
  const data = await fetchData(GetPhotoByID, {
    variables,
  });

  return data.data;
};
